export const VARIANT_QUERY = `
  id
  sku
  title
  availableForSale
  quantityAvailable
  currentlyNotInStock
  priceV2 {
    currencyCode
    amount
  }
  metafields(first: 5, namespace: "inventory") {
    edges {
      node {
        id
        updatedAt
        key
        value
      }
    }
  }
  presentmentPrices(first: 250) {
    edges {
      node {
        compareAtPrice {
          currencyCode
          amount
        }
        price {
          currencyCode
          amount
        }
      }
    }
  }
  image {
    id
    altText
    transformedSrc(maxWidth: 190, preferredContentType: JPG)
  }
  compareAtPriceV2 {
    currencyCode
    amount
  }
  selectedOptions {
    name
    value
  }
`

export const GET_VARIANTS_INVENTORY = `
query ($ids:[ID!]!) {
  nodes(ids: $ids) {
    ... on ProductVariant {
      id
      availableForSale
      quantityAvailable
      currentlyNotInStock
      metafields(first: 5, namespace: "inventory") {
        edges {
          node {
            id
            updatedAt
            key
            value
          }
        }
      }
    }
  }
}
`

export const PRODUCT_BY_HANDLE_QUERY_LOCALIZED = (
  contentfulLocale: string = "en-US",
  countryCode: string | undefined = "US"
) => `
query shopifyProduct($handle: String!) @inContext(country: ${countryCode}) {
  product: productByHandle(handle: $handle) {
    id
    title
    handle
    tags
    images(first: 20, sortKey: POSITION) {
      edges {
        node {
          id
          altText
          transformedSrc(maxWidth: 1280, maxHeight: 1280, preferredContentType: JPG)
        }
      }
    }
    contentfulData: metafield(namespace: "contentful", key: "product_data_${
      contentfulLocale && contentfulLocale.toLowerCase().replace("-", "_")
    }") {
      value
      key
      id
    }
    variants(first: 20) {
      edges {
        node {
          ${VARIANT_QUERY}
        }
      }
    }
  }
}
`

export const PRODUCTS_BY_HANDLES_QUERY_LOCALIZED = (locale: string | undefined, handles: any) => {
  const productByHandleFragments: string[] = handles.map(
    (handle: string) => `
${handle.toLowerCase().replaceAll("-", "_")}: productByHandle(handle:"${handle}"){
  id
  title
  handle
  contentfulData: metafield(namespace: "contentful", key: "product_data_${
    locale && locale.toLowerCase().replace("-", "_")
  }") {
    value
    key
    id
  }
}`
  )

  return `
  query shopifyProduct {
    ${productByHandleFragments.join("\n")}
  }
`
}

export const PRODUCTS_INVENTORY_BY_HANDLES_QUERY_LOCALIZED = (
  locale: string | undefined,
  handles: string[]
) => {
  const productByHandleFragments: string[] = handles.map(
    handle => `
  ${handle.toLowerCase().replaceAll("-", "_")}: productByHandle(handle:"${handle}"){
    id
    variants(first: 20) {
      edges {
        node {
          id
          metafields(first: 5, namespace: "inventory") {
            edges {
              node {
                id
                updatedAt
                key
                value
              }
            }
          }
        }
      }
    }
  }`
  )

  return `
    query shopifyProduct {
      ${productByHandleFragments.join("\n")}
    }
  `
}

export const CHECKOUT_QUERY = `
  id
  note
  currencyCode
  buyerIdentity {
    countryCode
  }
  completedAt
  lineItemsSubtotalPrice {
    amount
  }
  lineItems(first: 250) {
    edges {
      node {
        id
        title
        customAttributes {
          key
          value
        }
        quantity
        variant {
          ${VARIANT_QUERY}
          product {
            handle
          }
        }
      }
    }
  }
  orderStatusUrl
  webUrl
`

export const CHECKOUT_BY_ID_QUERY = `
  query shopifyCheckout($id: ID!) {
    checkout: node(id: $id) {
      id
      ... on Checkout {
        ${CHECKOUT_QUERY}
      }
    }
  }
`

export const PRODUCT_RECOMMENDATIONS_QUERY = `
  query getProductRecommendations($productId: ID!) {
    productRecommendations(productId: $productId) {
      id
      title
      availableForSale
      onlineStoreUrl
      images(first: 20, sortKey: POSITION) {
        edges {
          node {
            id
            altText
            transformedSrc(maxHeight: 640, preferredContentType: JPG)
          }
        }
      }
      variants(first: 250) {
        edges {
          node {
            ${VARIANT_QUERY}
          }
        }
      }
    }
  }
`

// Account

const CUSTOMER_ADDRESS_QUERY = `
  id
  firstName
  lastName
  address1
  address2
  company
  phone
  city
  country
  province
  zip
`

export const CUSTOMER_FIELDS = `
  firstName
  lastName
  acceptsMarketing
  phone
  email
  id
  metafields(first: 10) {
    edges {
      node {
        key
        value
        createdAt
      }
    }
  }
  defaultAddress {
    ${CUSTOMER_ADDRESS_QUERY}
  }
  addresses(first: 100) {
    edges {
      node {
        ${CUSTOMER_ADDRESS_QUERY}
      }
    }
  }
  orders(first:100, reverse: true){
    edges{
      node{
        id
        name
        orderNumber
        totalPrice
        processedAt
        cancelReason
        canceledAt
        statusUrl
        currencyCode
        financialStatus
        fulfillmentStatus
        subtotalPriceV2 {
          currencyCode
          amount
        }
        totalShippingPriceV2 {
          currencyCode
          amount
        }
        totalPriceV2 {
          currencyCode
          amount
        }
        totalRefundedV2 {
          currencyCode
          amount
        }
        totalTaxV2 {
          currencyCode
          amount
        }
        shippingAddress {
          ${CUSTOMER_ADDRESS_QUERY}
        }
        successfulFulfillments(first: 100){
          trackingCompany
          trackingInfo(first: 100){
            number
            url
          }
        }
        lineItems(first:100){
          edges {
            node {
              quantity
              title
              variant{
                id
                sku
                title
                priceV2 {
                  currencyCode
                  amount
                }
                image{
                  originalSrc
                }
                selectedOptions {
                  name
                  value
                }
              }
              originalTotalPrice {
                currencyCode
                amount
              }
            }
          }
        }
      }
    }
  }
`

export const CUSTOMER_QUERY = `query customerQuery($customerAccessToken: String!){
    customer(customerAccessToken: $customerAccessToken) {
      ${CUSTOMER_FIELDS}
    }
  }
`
